.flex-containerC {
	border: 5px solid rgb(55, 139, 22);
	width: 100%;
	height: 75%;
	align-items: center;
	display: flex;
}
.offline{background-color: #F9440C; font-weight: bold; position:absolute;}
.ticketme {border-bottom: 2px solid #CED1CE; padding-bottom: 0.2%; font-weight: bold; font-size: 18px;}
.ticket {background-color: #0588AE; color: #ffffff;}
.ticketCon {margin:1%; margin-left:20%;}
.tableCon {overflow:scroll; width: 100%; height: 700px;}
.customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.equalWCon {
	display: flex;
	gap: 10px; /* Optional spacing between items */
	width: 100%;
}

.equalCols {
	flex: 1; /* Makes all items take equal width */
	padding: 20px;
	background-color: lightblue;
	text-align: center;
	border: 1px solid #333;
}

.col65 {
	flex: 65%; /* Takes 65% of the container width */
}
.col70 {
	flex: 70%; /* Takes 65% of the container width */
}
.col30 {
	flex: 30%; /* Takes 65% of the container width */
}

.col35 {
	flex: 35%; /* Takes 35% of the container width */
}
.teaserHome {background: rgba(78, 68, 209, 0.7);  line-height:1.6;}
table {
	border-collapse: collapse; /* Ensure proper spacing in the table */
	width: 100%; /* Table width can extend */
}
.datarow {border-bottom: 1px solid #DEE2E4 !important; padding: 20px !important; margin: 0 !important;}
.datarow1 {border-bottom: 1px solid #DEE2E4 !important; border-top: 1px solid #DEE2E4 !important; padding: 20px !important; margin: 0 !important;}
.customers1 {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
  }
.active {color:#08B408;}
.customers td, .customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
.editCol {width: 10px;}
.editCol1 {width: 10px; font-size: 11px; writing-mode: vertical-rl; vertical-align: middle;}
thead { top: 0; }
.customers tr:nth-child(even){background-color: #f2f2f2;}
.customers tbody tr:hover {background-color: #ddd;}
.customers1 thead tr:hover {background-color:none;}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  word-wrap: break-word;
}

.customers thead {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: #2c5e77;
	color: white;
	word-wrap: break-word;
  }

  
.customers1 thead {
	text-align: left;
	background-color: #434444;
	color: white;
	word-wrap: break-word;
  }

.flex-itemC {
	flex: 1;
	height: 90%;
	text-align: center;
	background-color: rgb(184, 233, 181);
	border: 1px solid #ccc;
	margin: 5px 10px;
}


.thead {
	border-bottom: 2px solid rgb(160 160 160) !important;
	text-align: center !important;
	background-color: #2c5e77 !important;
	color: #fff !important;
  }
  
.tbody {
	background-color: #e4f0f5 !important;
  }
  

.header {background-color: #EAE8E7; font-weight: bold; width: 100%; text-align: left;}
.innerPageTitle {background-color: #EEF1F3; font-size:16px !important;}
.transDetail {min-width: 250px !important; border-bottom: 1px solid #404040;}
.checkImg {max-width:100px !important;}
.paymentSection:hover {background-color:#F3F3F3;}
.featureList {border-bottom:1px solid #DDDEE2;}
.features {color:#0066FF}
.featuresActive {background-color:#00B000; color:#FFFFFF;}
.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}

.ck-content h3.category {
	font-family: 'Oswald';
	font-size: 20px;
	font-weight: bold;
	color: #555;
	letter-spacing: 10px;
	margin: 0;
	padding: 0;
}

.ck-content h2.document-title {
	font-family: 'Oswald';
	font-size: 50px;
	font-weight: bold;
	margin: 0;
	padding: 0;
	border: 0;
}

.ck-content h3.document-subtitle {
	font-family: 'Oswald';
	font-size: 20px;
	color: #555;
	margin: 0 0 1em;
	font-weight: bold;
	padding: 0;
}

.ck-content p.info-box {
	--background-size: 30px;
	--background-color: #e91e63;
	padding: 1.2em 2em;
	border: 1px solid var(--background-color);
	background: linear-gradient(
			135deg,
			var(--background-color) 0%,
			var(--background-color) var(--background-size),
			transparent var(--background-size)
		),
		linear-gradient(
			135deg,
			transparent calc(100% - var(--background-size)),
			var(--background-color) calc(100% - var(--background-size)),
			var(--background-color)
		);
	border-radius: 10px;
	margin: 1.5em 2em;
	box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
	font-family: 'Oswald';
	font-style: normal;
	float: right;
	width: 35%;
	position: relative;
	border: 0;
	overflow: visible;
	z-index: 1;
	margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
	content: '�';
	position: absolute;
	top: -37px;
	left: -10px;
	display: block;
	font-size: 200px;
	color: #e7e7e7;
	z-index: -1;
	line-height: 1;
}

.ck-content blockquote.side-quote p {
	font-size: 2em;
	line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
	font-size: 1.3em;
	text-align: right;
	color: #555;
}

.ck-content span.marker {
	background: yellow;
}

.ck-content span.spoiler {
	background: #000;
	color: #000;
}

.ck-content span.spoiler:hover {
	background: #000;
	color: #fff;
}

.ck-content pre.fancy-code {
	border: 0;
	margin-left: 2em;
	margin-right: 2em;
	border-radius: 10px;
}

.codeCon {margin:0 !important; padding: 0 !important;}
.ck-content pre.fancy-code::before {
	content: '';
	display: block;
	height: 13px;
	background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
	margin-bottom: 8px;
	background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
	background: #272822;
	color: #fff;
	box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
	background: #dddfe0;
	color: #000;
	box-shadow: 5px 5px 0 #b3b3b3;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="file"]::file-selector-button {
  border: 1px solid #EEEEEE;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #F0F0F0;
  transition: 1s;
  cursor:pointer;
}

input[type="file"]::file-selector-button:hover {
  background-color: #DFDFDF;
  border: 2px solid #DFDFDF;
}
ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.cartRow:hover {background-color:#F3F3F3;}
.server1 {background-color:#A825C1}
.server2 {background-color:#4245FA}
.server3 {background-color:#5E4B8C}
.server4 {background-color:#FF7F00}

.server1B {border: 2PX solid #A825C1;}
.server2B {border: 2PX solid #4245FA;}
.server3B {border: 2PX solid #5E4B8C;}
.server4B {border: 2PX solid #FF7F00;}

.error {border:1px solid #FF6600 !important;}
.table {border:none !important; border-width:0 !important;}
.noBorder {border:none !important; border-width:0 !important;}
.noPM {background-color:#E7EAEE;}
.currencyImg  {width:22px !important; border:none;}
.currency  {font-size:12px !important; color:#FFFFFF; font-weight:bold;}
.helpText {font-size:12px !important;}
.consultancy {background-color:#F6F7F8 !important;}
.logoTopCon {max-width:150px !important;}
.defMrg {margin-left:5% !important; margin-right:5% !important;}
.defPad {padding-left:5% !important; padding-right:5% !important;}
.topMenuRow {background-color:#0080C0; color:#000000; font-weight:bold; color:#ffffff; font-size:12px  !important; }
.primaryMenu {color:#9E9FA0; font-weight:bold; font-family:Arial, Helvetica, sans-serif} 
.wrap {overflow-wrap: break-word !important; max-width:100% !important; }
.chatIcon {position:fixed !important; bottom:9% !important; right:2% !important; z-index:301; background-color:#0080C0; display: inline-block; cursor:pointer }
.scrollUp {position:fixed !important; bottom:9% !important; left:2% !important; z-index:300; background-color:#0080C0; border-radius: 50%; display: inline-block; cursor:pointer }
.footerCon {background-color:#000000; color:#FFFFFF}
.legal {color:#DCD9D9 !important; cursor:pointer; margin-bottom:1%; margin-right:3%}
.aboutLinks {color:#DCD9D9 !important; cursor:pointer; border-bottom:1px solid #7E807F;}
.topMenuRow2 {color:#000000; background-color:#ffffff; border-bottom:1px solid #0080C0;}
.folderList {min-width: 250px; max-width: 255px; background-color: #EFF6F7;}
.dirList {min-width: 30%; overflow-x: scroll;}
.sideMenuBar {width:5%}
.homePromoSec1 {width:30%; color: #272822;}
.sideBarBg {background-color:#F2F4F7;}
.sideMenuMobile {border-bottom:1px solid #999999}
.pageTitle {border-bottom:1px solid #000000;}
.pageTitle1 {border-bottom:1px solid #000000; font-weight:bold; font-size:16px !important;}
.homeTitle {color: #000fff;}
.homeText {color: #272822;}
.secondaryMenu {font-size:12px !important; font-weight:lighter !important;}
.hideElem {display:none;}
.rowItem {border-bottom: 1px solid #D0E4E7;}
.sentence {text-transform: capitalize;}
.code {background:#666666; color:#FFFFFF; padding:3%; cursor:pointer}

.table-container {
	max-width: 100%; /* Set the max width of the container */
	/* max-height: 300px; Set the max height of the container */
	overflow-x: auto; /* Enable horizontal scrolling */
	overflow-y: auto; /* Enable vertical scrolling */
}

#outer {
width:100%;
display: flex;
justify-content: center;
}

.vertical-center {
margin: 0;
position: absolute;
top: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);
}

a.homeTitle:link {color: #000fff !important; text-decoration:none}
a.homeTitle:hover {color:#000fff !important; text-decoration: none;}
a.homeTitle:visited {color: #000fff !important; text-decoration:none}
a.homeTitle:active { color: #000fff !important; text-decoration:none}

a.secondaryMenu:link {color: #ffffff !important; text-decoration:none}
a.secondaryMenu:hover {color:#ffffff !important; text-decoration: none;}
a.secondaryMenu:visited {color: #ffffff !important; text-decoration:none}
a.secondaryMenu:active { color: #ffffff !important; text-decoration:none}

a.primaryMenu:link {color:#676767 !important; text-decoration:none}
a.primaryMenu:hover {color:#676767 !important; text-decoration: none;}
a.primaryMenu:visited {color:#676767 !important; text-decoration:none}
a.primaryMenu:active { color:#676767 !important; text-decoration:none}

a.aboutLinks:link {color: #DCD9D9 !important; text-decoration:none}
a.aboutLinks:hover {color:#DCD9D9 !important; text-decoration: none;}
a.aboutLinks:visited {color:#DCD9D9 !important; text-decoration:none}
a.aboutLinks:active { color:#DCD9D9 !important; text-decoration:none}











.footHeader {font-size: 16px;}
.helpSidebar {border-bottom: 1px solid #D3D8D3;}
.cardHeader {background-color:#F3F3F3; color:#404040; text-transform:uppercase}
.activeProduct {border: 6px solid #C4C5C8 !important;}
.notfound {background-color:#104909; color:#ffffff; }
.imgFlag {max-width:20px !important;}
.topMenu1 {font-weight:lighter !important;}
.topMenu {background-color:#18AB0D; color:#ffffff; margin:0; padding:0; }
.sideMenu {color:#5D5F5C}
.fixedTop {position:absolute; right:5%; top:15%; min-width:200px; max-width:250px; background-color:#000000; color:#FFFFFF; z-index:10000 !important; font-size:12px; font-family:Geneva, Arial, Helvetica, sans-serif;}
.pointer {cursor:pointer}
.homeNewsletter {min-height:120px;}
.signUpTeaser {font-family:Verdana, Arial, Helvetica, sans-serif; font-size:16px; color:#E8EBEB;}
.catTitle {color:#3D3F3C; text-transform:uppercase; font-weight:bold; border-bottom:1px solid #666666}
.catTitle1 {color:#F5D607; text-transform:uppercase; font-weight:bold}
.footerTopDiv {border-top:#7E807F 1px solid;}
.copy1 {color:#E8EAEC; font-size:14px;}
.logoTopCon {max-width:120px !important;}
.activeproduct {border: 2px solid #000000; border-radius: 6px;}
.datacenter {border: 1px solid; font-size: 20px; text-transform: uppercase; color: #000000; opacity: 0.9; background-color: #EEF5EE; border-radius: 25px; }
.datacenterlocate {border: 1px solid; font-size: 20px; color: #000000; opacity: 0.9; background-color: #EEF5EE; }
.upcoming {max-width: 30% !important;}
.upcomingText {background-color: #F4712E; text-transform: uppercase; color: #ffffff; font-size: 12px !important;}

a.topMenu:link {color: #535453 !important; text-decoration:none}
a.topMenu:hover {color:#535453 !important; text-decoration: none;}
a.topMenu:visited {color: #535453 !important; text-decoration:none}
a.topMenu:active { color: #535453 !important; text-decoration:none}

a.btnLinkWhite:link {color: #ffffff !important; text-decoration:none}
a.btnLinkWhite:hover {color:#ffffff !important; text-decoration: none;}
a.btnLinkWhite:visited {color: #ffffff !important; text-decoration:none}
a.btnLinkWhite:active { color: #ffffff !important; text-decoration:none}

a.sideMenu:link {color: #5D5F5C !important; text-decoration:none}
a.sideMenu:hover {color:#5D5F5C !important; text-decoration: none;}
a.sideMenu:visited {color: #5D5F5C !important; text-decoration:none}
a.sideMenu:active { color: #5D5F5C !important; text-decoration:none}

a.legal:link {color: #DCD9D9 !important; text-decoration:none}
a.legal:hover {color:#DCD9D9 !important; text-decoration: none;}
a.legal:visited {color: #DCD9D9 !important; text-decoration:none}
a.legal:active { color: #DCD9D9 !important; text-decoration:none}

a.myAcct:link {color:#5F5F5F !important; text-decoration:none}
a.myAcct:hover {color:#5F5F5F !important; text-decoration:none;}
a.myAcct:visited {color:#5F5F5F !important; text-decoration:none}
a.myAcct:active { text-decoration:#5F5F5F;}




.progress-container {
width: 100%;
background-color: #f3f3f3;
border: 1px solid #ddd;
margin-top: 10px;
}
.progress-bar {
width: 0;
height: 20px;
background-color: #4caf50;
text-align: center;
color: white;
line-height: 20px;
}
.fancy-code {margin: 0 !important; font-size: 18px;}

@media screen and (min-width: 1310px) { .frmCon {width:40% !important;}  }
@media screen and (max-width: 1310px) { .frmCon {width:50% !important;}  }
@media screen and (max-width: 1110px) { .frmCon {width:60% !important;}  }
@media screen and (max-width: 910px) { .frmCon {width:70% !important;}  }
@media screen and (max-width: 710px){ .frmCon {width:80% !important;}  }
@media screen and (max-width: 550px) { .frmCon {width:95% !important;}  }



@media screen and (min-width: 1310px) { .content {margin-left:5% !important; margin-right:5% !important;}  }
@media screen and (max-width: 1310px) { .content {margin-left:5% !important; margin-right:5% !important;}  }
@media screen and (max-width: 1110px) { .content {margin-left:5% !important; margin-right:5% !important;}  }
@media screen and (max-width: 910px) { .content {margin-left:5% !important; margin-right:5% !important;}  }
@media screen and (max-width: 710px){ .content {margin-left:4% !important; margin-right:4% !important;}  }
@media screen and (max-width: 550px) { .content {margin-left:2% !important; margin-right:2% !important;}  }



@media screen and (min-width: 1310px) { .loginCon1 {width:40% !important;}  }
@media screen and (max-width: 1310px) { .loginCon1 {width:50% !important;}  }
@media screen and (max-width: 1110px) { .loginCon1 {width:60% !important;}  }
@media screen and (max-width: 910px) { .loginCon1 {width:70% !important;}  }
@media screen and (max-width: 710px){ .loginCon1 {width:80% !important;}  }
@media screen and (max-width: 550px) { .loginCon1 {width:95% !important;}  }

@media screen and (min-width: 1310px) { .loginCon {width:80% !important;}  }
@media screen and (max-width: 1310px) { .loginCon {width:80% !important;}  }
@media screen and (max-width: 1110px) { .loginCon {width:80% !important;}  }
@media screen and (max-width: 910px)  { .loginCon {width:80% !important;}  }
@media screen and (max-width: 710px)  { .loginCon {width:80% !important;}  }
@media screen and (max-width: 550px)  { .loginCon {width:95% !important;}  }


@media screen and (min-width: 992px)  { .sideBar {min-width: 300px;}  }